import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/video-doorbell",
  "title": "Video Doorbell",
  "started": "2022-01-15T00:00:00.000Z",
  "published": "2022-02-01T00:00:00.000Z",
  "backgroundImage": "surveillance",
  "tags": ["Video doorbell", "security", "diy"],
  "layoutClass": "video-doorbell",
  "thumbnail": "blog/2022-01-15-video-doorbell/amcrest-AD110.jpg"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`My wife liked the idea of a video doorbell, so I had a look to see what is available.
There are a lot of options!`}</p>
    <p>{`To narrow down the choice, I decided I wanted the following:`}</p>
    <ul>
      <li parentName="ul">{`To be able to use the doorbell even if the manufacturer abandons the product (no reliance on an app or cloud to use
its features).`}</li>
      <li parentName="ul">{`To continue using the doorbell and ideally video access even during an internet outage.`}</li>
      <li parentName="ul">{`Not be battery powered - I don't want to be replacing batteries or having to worry about their charge.
This is certainly important if I want to start playing with the video feed from the camera rather than only having a
short piece of footage for each doorbell press.`}</li>
      <li parentName="ul">{`Preferably not be an Amazon or Google product.`}</li>
    </ul>
    <p>{`Because I have some history with playing with a `}<a parentName="p" {...{
        "href": "https://wyze.com/wyze-cam-v2.html"
      }}>{`Wyze Cam v2 IP camera`}</a>{`
(which has `}<a parentName="p" {...{
        "href": "https://github.com/EliasKotlyar/Xiaomi-Dafang-Hacks"
      }}>{`custom firmware`}</a>{` to enable an RTSP stream and to disable
the cloud features that come with the camera) I started my search looking for a doorbell that had an accessible RTSP
stream.
This lead me to a blog post (which of course I can't find now) where someone was able to set up their Amcrest AD110
doorbell to access its RTSP stream, as well as receive notifications of the doorbell press even if the doorbell itself
was firewalled off from the internet.
It is also a wired doorbell, and works with existing wired doorbells.
More on this later.`}</p>
    <p>{`Eventually while considering my options, the doorbell that came with the house stopped working.
It is likely that the battery died but when removing the doorbell button from the door it turned out the case had been
glued closed, and when prying it apart the casing disintegrated.
This pushed the issue so I decided to just go ahead and buy it - no point in buying a temporary wireless doorbell in the
short term, right?`}</p>
    <h2>{`Amcrest AD110`}</h2>
    <div className="image-left">
  <Image alt="Amcrest AD110" path="blog/2022-01-15-video-doorbell/amcrest-AD110.jpg" mdxType="Image" />
    </div>
    <p>{`The device seems fine - I'm not after a specific aesthetic to match a period property or anything, so a doorbell is a
doorbell.
It captures video at 1920x1080p resolution, a viewing angle of 140º, and built in night vision that is supposed to work
for approaching 5 metres.
It has a microSD card slot so I also purchased a compatible 64GB drive as I anticipate it'll take some time to get round
to starting a project to utilise the RTSP stream.`}</p>
    <p>{`It gives some audible feedback when the button is pressed which is nice - I've been waiting outside a front door
wondering if their doorbell even works far too many times!`}</p>
    <h2>{`Installation`}</h2>
    <p>{`The doorbell itself seems to be designed to simply replace an existing wired doorbell, but did I mention that our
existing doorbell was one of those simple battery powered wireless ones with a plug-in chime?
Yeah, that means I have to get into adding a chime and its associated wiring.
Nothing seems particularly complicated, and it is all low voltage, but not something I've had to deal with before.`}</p>
    <p>{`It did, however, mean that I had a few more parts to buy - a chime and a transformer, and enough wire to join them
together in sensible positions.`}</p>
    <h2>{`Chime and Transformer`}</h2>
    <p>{`I decided to buy a mechanical chime, as while I'm sure there are plenty of chimes with nice sounds, most of the
electronic chimes I have heard sound awfully artificial - and when buying online at least, don't get to hear them first.
Also I think there is something nice about the dings and dongs from a mechanical chime.`}</p>
    <p>{`The chime I went for was the `}<a parentName="p" {...{
        "href": "https://livewell.honeywellhome.com/en/data-sheet/?product=24503&type=classic"
      }}>{`Honeywell D117`}</a>{`
(catchy name!).
Despite what the the website says, the packaging and chime itself says it will work from 8 to 16V AC.`}</p>
    <p>{`To power doorbell will take 16-24V AC, so I should have a reasonable choice of adapters.
This turned out to be a little hard as I didn't find non-DIN mounted transformers of `}<em parentName="p">{`quite`}</em>{` the right voltage, so I
ended up buying an 18V transformer - what's 2 volts between friends...`}</p>
    <p>{`A quick wire up before installation showed that it all worked just fine.
The wiring itself is `}<em parentName="p">{`reasonably`}</em>{` straight forward, but it would involve drilling through the exterior wall of my house
to the porch, as well as hole through the porch wall to outside.`}</p>
    <div className="image-center">
  <Image alt="Wiring diagram" path="drawings/2022-01-15-video-doorbell/doorbell-wiring.png" mdxType="Image" />
    </div>
    <p>{`However, once the doorbell was on the exterior wall and all internal wiring was complete (though with un-trimmed wires)
It seemed that the chime would not sound when the doorbell was activated.
I could hear its little electromagnet activate, but no movement of the striker.
Strangely, removing the supposedly required "Chime Kit" allowed it all to work.`}</p>
    <p>{`Luckily, once the wires were trimmed to a sensible length and the chime screwed to the wall it worked just fine with the
chime kit installed as per the instructions.`}</p>
    <h2>{`Living with the Device`}</h2>
    <h3>{`App`}</h3>
    <p>{`The app is a little clunky, but it does seem to work just fine.
My biggest gripe by far is that it doesn't allow passwords to be pasted into fields, which means it requires entry via
my phone's keyboard - less than ideal for strong passwords.`}</p>
    <p>{`Each device has its own master device password which can be used for RTSP authentication.
However, due to me not being able to copy the password that I created in my password manager into the password filed
when I set it up and the password requirements not being met by my chosen password, I managed to not save the device
password which necessitates the device to be removed from my account before a factory reset.
I guess this is a reasonable process to re-gain access to the device to dissuade theft of video doorbells.`}</p>
    <p>{`Regions used for motion detection can be pretty finely controlled, though a downside with the interface is that you can't
remove regions without starting over.
Also, the PIR sensitivity can be limited from (approximately) 0 to 10 metres, though the choice for a 1-6 valued option
seems unusual for this.`}</p>
    <p>{`Even with these controls, I have yet to optimise it to filter out cars passing by.`}</p>
    <h3>{`As a Doorbell`}</h3>
    <p>{`So far it has worked just fine as a doorbell, perhaps the chime is a little on the quiet side, but it as least
accompanied by a push notification to my phone.`}</p>
    <h3>{`Video Access`}</h3>
    <p>{`I don't have any immediate plans to use the RTSP other than being able to look at the stream without using the app on my
phone, but I like the idea of putting the images through some sort of face/object detection.
I also don't know when I'm going to get around to such a project.`}</p>
    <p>{`It also requires me to change my device password first...`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      